<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="10"
      )
        h1.text-h4.mb-4 {{ verbage }} Parts Kit
        v-card(
          outlined
        )
          FeathersVuexFormWrapper(
            v-if="item"
            :item="item"
            watch
          )
            template(v-slot="{ clone, save, reset, isDirty }")
              .card
                v-form(
                  @submit.prevent="saveKit(save)"
                  ref="form"
                  v-model="valid"
                )
                  v-card-text
                    v-row
                      v-col
                        v-text-field(
                          label="Title"
                          filled
                          outlined
                          v-model="clone.title"
                          :rules="[rules.required]"
                        )
                        v-textarea(
                          filled
                          outlined
                          rows='4'
                          label="Description"
                          v-model="clone.description"
                        )
                  v-data-table.clickable(
                    :headers="headers"
                    no-data-text="No Parts Found"
                    disable-pagination
                    :items="parts"
                    @click:part=""
                    hide-default-footer
                    :items-per-page="-1"
                  )
                    template(#item.qty="{ item: part }")
                      v-text-field(
                        outlined
                        min="1"
                        v-model.number='part.qty'
                        :rules='[rules.required]'
                        hide-details="auto"
                        label='Edit'
                        single-line
                        type="number"
                        dense
                        style="width: 60px;"
                      )

                    template(#item.actions="{ item: product }")
                      v-btn(
                        icon
                        @click="removePart(product)"
                      )
                        v-icon mdi-delete

                    template(#item.listPrice="{ item: product }")
                      v-edit-dialog(
                        large
                        v-model="product.listPrice"
                      ) ${{ Number(product.listPrice).toFixed(2) | round(2) }}
                        template(v-slot:input)
                          v-text-field(
                            v-model="product.listPrice"
                            single-line
                          )

                    template(#item.costPrice="{ item: product }")
                      v-edit-dialog(
                        large
                        v-model="product.costPrice"
                      ) ${{ Number(product.costPrice).toFixed(2) | round(2) }}
                        template(v-slot:input)
                          v-text-field(
                            v-model="product.costPrice"
                            single-line
                          )
                    template(#body.prepend)
                      tr
                        td.pa-4(colspan="100")
                          ProductsAutocomplete(
                            @change="addPart"
                            :shopId="shopId"
                          )

                  v-divider
                  v-card-actions.pa-4
                    v-spacer
                    v-btn(
                      @click="cancel"
                    ) Cancel
                    v-btn(
                      color="primary"
                      type="submit"
                      :disabled="!valid"
                      min-width="150px"
                    ) Save
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex'
import validations from '@/mixins/validationRulesMixin'
import ProductsAutocomplete from '@/components/ProductsAutocomplete'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'EditKit',
  components: {
    FeathersVuexFormWrapper,
    ProductsAutocomplete
  },
  mixins: [
    validations
  ],
  computed: {
    verbage () {
      return this.kitId === 'new' ? 'Create' : 'Edit'
    },
    kitId () {
      return this.$route.params.kitId
    },
    shopId () {
      return this.$route.query.shopId || this.item.shop_id
    },
    isEdit () {
      return this.kitId !== 'new'
    },
    // Returns a new Invoice if the route `id` is 'new', or returns an existing Invoice.
    item () {
      const { Kit } = this.$FeathersVuex.api

      const item = this.kitId === 'new' ? new Kit({ shop_id: this.shopId }) : Kit.getFromStore(this.kitId)

      return item
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Qty.',
        value: 'qty'
      },
      {
        text: 'Part',
        value: 'part'
      },
      {
        text: 'Description',
        value: 'description'
      },
      {
        text: 'Cost Price',
        value: 'costPrice'
      },
      {
        text: 'List Price',
        value: 'listPrice'
      },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    parts: [],
    partsToRemove: []
  }),
  watch: {
    kitId: {
      async handler (val) {
        // Early return if the route `:id` is 'new'
        if (val === 'new') {
          return
        }

        const { Kit } = this.$FeathersVuex.api
        const existingRecord = Kit.getFromStore(val)

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Kit.get(val)
        }

        const { KitPart } = this.$FeathersVuex.api

        const { data: parts } = await KitPart.find({ query: { kit_id: this.kitId, $limit: 99 } })

        if (parts.length) {
          this.parts = parts
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true
    }
  },
  methods: {
    addPart (product) {
      this.isProductDialogOpen = false

      const { KitPart } = this.$FeathersVuex.api

      const newProduct = new KitPart({ costPrice: product.costPrice, part: product.name, product_id: product.id, listPrice: product.listPrice, description: product.description })

      this.parts.push(newProduct)
    },
    removePart (part) {
      //                                    Use this method to add the instance to the array
      this.partsToRemove = this.partsToRemove.concat([part])

      const editedIndex = this.parts.indexOf(part)
      this.parts.splice(editedIndex, 1)
    },
    async saveKit (save) {
      try {
        const savedKit = await save()

        this.parts.map(part => { part.kit_id = savedKit.id })

        for (let i = 0; i < this.parts.length; i++) {
          await this.parts[i].save()
        }

        for (let i = 0; i < this.partsToRemove.length; i++) {
          const part = this.partsToRemove[i]
          await part.remove()
        }

        // Send empty patch to trigger recalculation
        await savedKit.patch({ data: {} })

        this.$router.push({ name: 'kit', params: { kitId: savedKit.id } })

        const operativeWord = this.isEdit ? 'updated' : 'created'

        this.$snackSuccess('Kit ' + operativeWord)
      } catch (error) {
        console.error(error)
      }
    },
    cancel () {
      if (this.isEdit) {
        this.$router.push({ name: 'kit', params: { kitId: this.item.id } })
      } else {
        this.$router.push({ name: 'shop', params: { shopId: this.item.shop_id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .disclaimer {
    font-family: 'Hemi';
  }
</style>
