var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('h1',{staticClass:"text-h4 mb-4"},[_vm._v(_vm._s(_vm.verbage)+" Parts Kit")]),_c('v-card',{attrs:{"outlined":""}},[(_vm.item)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var clone = ref.clone;
var save = ref.save;
var reset = ref.reset;
var isDirty = ref.isDirty;
return [_c('div',{staticClass:"card"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveKit(save)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Title","filled":"","outlined":"","rules":[_vm.rules.required]},model:{value:(clone.title),callback:function ($$v) {_vm.$set(clone, "title", $$v)},expression:"clone.title"}}),_c('v-textarea',{attrs:{"filled":"","outlined":"","rows":"4","label":"Description"},model:{value:(clone.description),callback:function ($$v) {_vm.$set(clone, "description", $$v)},expression:"clone.description"}})],1)],1)],1),_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"no-data-text":"No Parts Found","disable-pagination":"","items":_vm.parts,"hide-default-footer":"","items-per-page":-1},on:{"click:part":function($event){}},scopedSlots:_vm._u([{key:"item.qty",fn:function(ref){
var part = ref.item;
return [_c('v-text-field',{staticStyle:{"width":"60px"},attrs:{"outlined":"","min":"1","rules":[_vm.rules.required],"hide-details":"auto","label":"Edit","single-line":"","type":"number","dense":""},model:{value:(part.qty),callback:function ($$v) {_vm.$set(part, "qty", _vm._n($$v))},expression:"part.qty"}})]}},{key:"item.actions",fn:function(ref){
var product = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removePart(product)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.listPrice",fn:function(ref){
var product = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":""},model:{value:(product.listPrice),callback:function ($$v) {_vm.$set(product, "listPrice", $$v)},expression:"product.listPrice"}})]},proxy:true}],null,true),model:{value:(product.listPrice),callback:function ($$v) {_vm.$set(product, "listPrice", $$v)},expression:"product.listPrice"}},[_vm._v("$"+_vm._s(_vm._f("round")(Number(product.listPrice).toFixed(2),2)))])]}},{key:"item.costPrice",fn:function(ref){
var product = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":""},model:{value:(product.costPrice),callback:function ($$v) {_vm.$set(product, "costPrice", $$v)},expression:"product.costPrice"}})]},proxy:true}],null,true),model:{value:(product.costPrice),callback:function ($$v) {_vm.$set(product, "costPrice", $$v)},expression:"product.costPrice"}},[_vm._v("$"+_vm._s(_vm._f("round")(Number(product.costPrice).toFixed(2),2)))])]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{staticClass:"pa-4",attrs:{"colspan":"100"}},[_c('ProductsAutocomplete',{attrs:{"shopId":_vm.shopId},on:{"change":_vm.addPart}})],1)])]},proxy:true}],null,true)}),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":!_vm.valid,"min-width":"150px"}},[_vm._v("Save")])],1)],1)],1)]}}],null,false,315327841)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }